import { Link, makeStyles, TreeItemLayout } from '@fluentui/react-components';
import { ArrowDownload20Regular } from '@fluentui/react-icons';
import { Duration, Interval } from 'luxon';
import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import { Metric } from '../../constants/telemetry';
import { trackMetric } from '../../utilities/telemetry/channel';

interface CustomizedTreeHeaderProps {
    statusIcon?: JSX.Element;
    name: string;
    startTime?: Date;
    endTime?: Date;
    logUrl?: string;
    logFileName?: string;
    alwaysShowDownloadIcon?: boolean;
}

interface ActionContentProps {
    downloadIcon?: JSX.Element;
}

interface AsideContentProps {
    completionTime?: JSX.Element;
    downloadIcon?: JSX.Element;
    showDownloadIcon?: boolean;
}

/**
 * Styles
 */

const useHeaderStyles = makeStyles({
    root: {
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    info: {
        paddingTop: '2px',
    },
});

/**
 * End Styles
 */

const AsideContent: React.FC<AsideContentProps> = React.memo((props: AsideContentProps) => {
    const { completionTime, downloadIcon, showDownloadIcon } = props;

    const headerStyles = useHeaderStyles();

    return (
        <>
            <div className={headerStyles.info}>{completionTime}</div>
            {!!showDownloadIcon ? <div className={headerStyles.info}>{downloadIcon}</div> : undefined}
        </>
    );
});

const ActionContent: React.FC<ActionContentProps> = React.memo((props: ActionContentProps) => {
    const { downloadIcon } = props;

    const headerStyles = useHeaderStyles();

    return <div className={headerStyles.info}>{downloadIcon}</div>;
});

const onLinkClick = () => {
    trackMetric(Metric.EnvironmentLogsDownloaded, 1);
};

export const CustomizedTreeHeader: React.FC<CustomizedTreeHeaderProps> = (props: CustomizedTreeHeaderProps) => {
    const { name, statusIcon, startTime, endTime, logUrl, logFileName, alwaysShowDownloadIcon } = props;

    const headerStyles = useHeaderStyles();

    const timeTaken = React.useMemo(
        () =>
            !!endTime && !!startTime
                ? Interval.fromDateTimes(startTime, endTime).toDuration(['minutes', 'seconds'])
                : 0,
        [startTime, endTime]
    );

    const downloadIcon = React.useMemo(
        () =>
            startTime &&
            endTime && (
                <Link href={logUrl} download={logFileName} target="_blank" rel="noreferrer" onClick={onLinkClick}>
                    <ArrowDownload20Regular />
                </Link>
            ),
        [startTime, endTime, logUrl, logFileName]
    );

    const completionTime = React.useMemo(() => {
        if (startTime === undefined || endTime === undefined) {
            return <></>;
        }

        const minutes = Math.round((timeTaken as Duration).minutes);
        const seconds = Math.round((timeTaken as Duration).seconds);

        const formattedSeconds = <FormattedNumber value={seconds} style="unit" unit="second" unitDisplay="narrow" />;

        if (minutes !== 0) {
            const formattedMinutes = (
                <FormattedNumber value={minutes} style="unit" unit="minute" unitDisplay="narrow" />
            );
            return (
                <>
                    {formattedMinutes} {formattedSeconds}
                </>
            );
        }

        return formattedSeconds;
    }, [startTime, endTime, timeTaken]);

    return (
        <TreeItemLayout
            className={headerStyles.root}
            iconBefore={statusIcon}
            aside={
                <AsideContent
                    completionTime={completionTime}
                    downloadIcon={downloadIcon}
                    showDownloadIcon={alwaysShowDownloadIcon}
                />
            }
            actions={!!alwaysShowDownloadIcon ? undefined : <ActionContent downloadIcon={downloadIcon} />}
        >
            {name}
        </TreeItemLayout>
    );
};
