import { createSelector } from '@reduxjs/toolkit';
import { getTokensFromPoolDataPlaneUri } from '../../ids/pool';
import { createProjectDataPlaneUri } from '../../ids/project';
import { Entity, Status } from '../../models/common';
import { Pool, PoolHealthStatus } from '../../models/pool';
import { unique } from '../../utilities/array';
import { get } from '../../utilities/serializable-map';
import { statusAdapter } from '../adapters/common/status-adapter';
import { poolAdapter } from '../adapters/pool-adapters';
import { StoreState } from '../store/store-state';
import {
    StoreStateSelector,
    createGroupedMapFromEntitiesSelector,
    createMapFromEntitiesSelector,
    getStringIdsSelector,
    isStatusTerminal,
} from './common';
import { getStatusesForListSchedulesByProject } from './schedule-selectors';

/**
 * Basic selectors
 */

export const getStatusForDiscoverPoolsForProjects: StoreStateSelector<Status> = (store) =>
    store.poolStore.status.discoverPoolsForProjects;

export const getStatusForDiscoverPoolsForDevBoxes: StoreStateSelector<Status> = (store) =>
    store.poolStore.status.discoverPoolsForDevBoxes;

/**
 * Entity state selectors
 */

const poolSelectors = poolAdapter.getSelectors<StoreState>((store) => store.poolStore.data.pools);

const statusesForListPoolsSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.poolStore.status.listPools
);

/**
 * Composed selectors
 */

export const getDataPlaneIdentifiersForPools = getStringIdsSelector(poolAdapter, (store) => store.poolStore.data.pools);

export const getPools = createMapFromEntitiesSelector(poolSelectors.selectAll);

export const getHasPools: StoreStateSelector<boolean> = createSelector(
    [poolSelectors.selectTotal],
    (count) => count > 0
);

// Gets only pools that are healthy, filters out pools with healthStatus of Unhealthy
export const getHealthyPools: StoreStateSelector<Entity<Pool>[]> = createSelector(poolSelectors.selectAll, (pools) =>
    pools.filter((pool) => pool.data.healthStatus !== PoolHealthStatus.Unhealthy)
);

export const getPoolsByProject = createGroupedMapFromEntitiesSelector(getHealthyPools, (entity) => {
    const { id } = entity;
    const { devCenter, projectName } = getTokensFromPoolDataPlaneUri(id);
    return createProjectDataPlaneUri({ devCenter, projectName });
});

export const getProjectUrisForPoolsRequiringSchedules: StoreStateSelector<string[]> = createSelector(
    [getDataPlaneIdentifiersForPools, getStatusesForListSchedulesByProject],
    (ids, statusesForListSchedulesByProject) => {
        const projectIds = unique(
            ids.map((id) => {
                const { devCenter, projectName } = getTokensFromPoolDataPlaneUri(id);
                return createProjectDataPlaneUri({ devCenter, projectName });
            })
        );

        return projectIds.filter((projectId) => !isStatusTerminal(get(statusesForListSchedulesByProject, projectId)));
    }
);

export const getStatusesForListPools = createMapFromEntitiesSelector(statusesForListPoolsSelectors.selectAll);
