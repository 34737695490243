import { createSelector } from 'reselect';
import { EnvironmentOperation } from '../../models/environment';
import { get } from '../../utilities/serializable-map';
import { environmentOperationAdapter, environmentOperationLogAdapter } from '../adapters/environment-operation-adapter';
import { AsyncState } from '../store/common-state';
import { StoreState } from '../store/store-state';
import { createArrayFromEntitiesSelector, createMapFromEntitiesSelector, StoreStateSelector } from './common';
import { statusAdapter } from '../adapters/common/status-adapter';

/**
 * Entity state selectors
 */

const environmentOperationLogSelectors = environmentOperationLogAdapter.getSelectors<StoreState>(
    (store) => store.environmentOperationsStore.data.logUris
);

const environmentOperationSelectors = environmentOperationAdapter.getSelectors<StoreState>(
    (store) => store.environmentOperationsStore.data.operations
);

const environmentOperationLogStatusSelectors = statusAdapter.getSelectors<StoreState>(
    (store) => store.environmentOperationsStore.status.getEnvironmentOperationLog
);

/**
 * Composed selectors
 */

export const getEnvironmentOperationLogs = createMapFromEntitiesSelector(environmentOperationLogSelectors.selectAll);

export const getEnvironmentOperations = createArrayFromEntitiesSelector(environmentOperationSelectors.selectAll);

export const getEnvironmentOperationLogStatuses = createMapFromEntitiesSelector(
    environmentOperationLogStatusSelectors.selectAll
);

export const getEnvironmentOperationUrisToFetchLogsFor = (
    operations: EnvironmentOperation[]
): StoreStateSelector<string[]> =>
    createSelector([getEnvironmentOperationLogStatuses], (statuses) =>
        operations
            .filter((operation) => get(statuses, operation.uri)?.state !== AsyncState.Success)
            .map((operation) => operation.uri)
    );
