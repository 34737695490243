import { SagaIterator } from '@redux-saga/types';
import { all, put, select } from 'redux-saga/effects';
import { ClientError, FailureOperation } from '../../../models/common';
import { EnvironmentOperation } from '../../../models/environment';
import { getEnvironmentOperationUrisToFetchLogsFor } from '../../selector/environment-operation-selectors';
import {
    getEnvironmentOperationLog,
    listEnvironmentOperations,
    loadEnvironmentOperationsAndLogs as loadEnvironmentOperationsAndLogsActionCreator,
    loadEnvironmentOperationsAndLogsError,
    loadEnvironmentOperationsAndLogsSuccess,
} from '../../actions/environment-operation/environment-operation-action-creators';
import { LoadEnvironmentOperationsAndLogsAction } from '../../actions/environment-operation/environment-operation-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { takeEvery } from '../../effects/take';

export function* loadEnvironmentOperationsAndLogsSaga(action: LoadEnvironmentOperationsAndLogsAction): SagaIterator {
    const { payload } = action;
    const { id } = payload;

    try {
        // Get and update store with new operations
        const operations: EnvironmentOperation[] = (yield putAndAwait(listEnvironmentOperations({ id }))).data;

        // Get log only for new environment operations
        // This is to prevent panel component from re-rendering each time due to new blob URI being re-generated for the same log
        const operationUris: string[] = yield select(getEnvironmentOperationUrisToFetchLogsFor(operations));
        yield all(operationUris.map((uri) => putAndAwait(getEnvironmentOperationLog({ id: uri }))));

        yield put(loadEnvironmentOperationsAndLogsSuccess({ id }));
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.LoadEnvironmentOperationsAndLogs);
        yield put(loadEnvironmentOperationsAndLogsError({ error, id }));
    }
}

export function* loadEnvironmentOperationsAndLogsListenerSaga(): SagaIterator {
    yield takeEvery(loadEnvironmentOperationsAndLogsActionCreator, loadEnvironmentOperationsAndLogsSaga);
}
