import { Divider } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import {
    EnvironmentFailureReason,
    EnvironmentProvisioningState,
    EnvironmentResourceErrorCode,
} from '../../../constants/environment';
import { FailureOnEnvironment } from '../../../models/environment';
import { OldMetadataItemViewModel } from '../../common/metadata/models';
import {
    ResourceCardBannerKind,
    ResourceCardBannerViewModel,
    ResourceCardError,
    ResourceCardErrorSeverity,
} from '../../resource-card/models';

const unknownFailureFormattedMessage = (
    <FormattedMessage
        id="EnvironmentCard_UnknownOperationMessage_Text"
        defaultMessage="This environment is in a failed state. Please delete and recreate it, or contact your admin."
        description="Failure message informing the user that an unknown operation has failed for a environment."
    />
);

const getFailureMessageForEnvironmentResourceCode = (code: string | undefined): React.ReactNode | undefined => {
    // Special handling for resource errors
    switch (code) {
        case EnvironmentResourceErrorCode.DeploymentCanceled:
            return (
                <FormattedMessage
                    id="EnvironmentCard_DeploymentCanceled_Text"
                    defaultMessage="The environment has exceeded the runtime limit per deployment, and could not be created. Please contact your admin to increase the quota."
                    description="Failure message informing the user that creating (a.k.a. provisioning) the environment failed because the deployment exceeded the runtime limit."
                />
            );
        case EnvironmentResourceErrorCode.EnvironmentStorageExceeded:
            return (
                <FormattedMessage
                    id="EnvironmentCard_EnvironmentStorageExceededError_Text"
                    defaultMessage="The environment has exceeded the storage limit, and could not be created. Please contact your admin to increase the quota."
                    description="Failure message informing the user that creating (a.k.a. provisioning) the environment failed because the deployment exceeded the storage limit."
                />
            );
        default:
            return undefined;
    }
};

const getFormattedMessageForFailure = (failureOnEnvironment: FailureOnEnvironment): React.ReactNode => {
    const { failure, reason } = failureOnEnvironment;

    // If we have a specialized message for this error code, use that
    const resourceCodeSpecializedMessage = getFailureMessageForEnvironmentResourceCode(failure.code);

    if (resourceCodeSpecializedMessage) {
        return resourceCodeSpecializedMessage;
    }

    switch (reason) {
        case EnvironmentFailureReason.CreateFailed:
            return (
                <FormattedMessage
                    id="EnvironmentCard_CreateOperationMessage_Text"
                    defaultMessage="Provisioning of this environment failed. Please delete and recreate it, or contact your admin."
                    description="Failure message informing the user that creating (a.k.a. provisioning) the environment failed."
                />
            );
        case EnvironmentFailureReason.DeleteFailed:
            return (
                <FormattedMessage
                    id="EnvironmentCard_DeleteOperationMessage_Text"
                    defaultMessage="Deleting this environment failed. Please try again."
                    description="Failure message informing the user that deleting the environment failed."
                />
            );
        case EnvironmentFailureReason.DeployFailed:
            return (
                <FormattedMessage
                    id="EnvironmentCard_RedeployOperationMessage_Text"
                    defaultMessage="Redeploying this environment failed. Please try again."
                    description="Failure message informing the user that redeploying the environment failed."
                />
            );
        case EnvironmentFailureReason.UpdateFailed:
            return (
                <FormattedMessage
                    id="EnvironmentCard_ExpirationUpdateFailedMessage_Text"
                    defaultMessage="Changing the expiration failed. Please try again."
                    description="Failure message informing the user that changing the expiration on the environment failed."
                />
            );
        case EnvironmentFailureReason.ProvisioningCanceled:
        case EnvironmentFailureReason.ProvisioningFailed:
        default:
            return unknownFailureFormattedMessage;
    }
};

const getSeverityForFailure = (failureOnEnvironment: FailureOnEnvironment): ResourceCardErrorSeverity => {
    const { reason } = failureOnEnvironment;

    // Delete is a low severity error
    if (reason === EnvironmentFailureReason.DeleteFailed) {
        return ResourceCardErrorSeverity.Low;
    }

    // All other errors are high severity
    return ResourceCardErrorSeverity.High;
};

export const getAreActionsDisabled = (state: EnvironmentProvisioningState): boolean => {
    switch (state) {
        case EnvironmentProvisioningState.Deleting:
        case EnvironmentProvisioningState.Deploying:
        case EnvironmentProvisioningState.Updating:
            return true;
        default:
            return false;
    }
};

export const getMetadata = (
    environmentTypeMetadata: OldMetadataItemViewModel,
    ownerMetadata: OldMetadataItemViewModel,
    resourceGroupMetadata: OldMetadataItemViewModel | undefined,
    expirationDateOffsetMetadata: OldMetadataItemViewModel | undefined,
    state: EnvironmentProvisioningState
): OldMetadataItemViewModel[] => {
    if (state === EnvironmentProvisioningState.Creating) {
        return [];
    }

    const metadata = [environmentTypeMetadata, ownerMetadata];

    if (!!expirationDateOffsetMetadata) {
        metadata.push(expirationDateOffsetMetadata);
    }

    if (!!resourceGroupMetadata) {
        metadata.push(resourceGroupMetadata);
    }

    return metadata;
};

export const getResourceCardError = (
    failureOnEnvironment: FailureOnEnvironment | undefined,
    state: EnvironmentProvisioningState
): ResourceCardError | undefined => {
    if (failureOnEnvironment) {
        // Note: no failure are retry-able at the current moment
        return {
            message: getFormattedMessageForFailure(failureOnEnvironment),
            severity: getSeverityForFailure(failureOnEnvironment),
        };
    }

    // Catch cases where resource is missing error but is in a failed state
    if (state === EnvironmentProvisioningState.Canceled || state === EnvironmentProvisioningState.Failed) {
        return {
            message: unknownFailureFormattedMessage,
            severity: ResourceCardErrorSeverity.High,
        };
    }

    return undefined;
};

export const getSecondaryActions = (
    deleteMenuItem: JSX.Element,
    redeployMenuItem: JSX.Element,
    changeExpirationMenuItem: JSX.Element,
    state: EnvironmentProvisioningState,
    isRedeployReady: boolean,
    moreInfoMenuItem: JSX.Element,
    deploymentLogsItem: JSX.Element
): JSX.Element[] => {
    switch (state) {
        case EnvironmentProvisioningState.Canceled:
        case EnvironmentProvisioningState.Succeeded:
        case EnvironmentProvisioningState.Failed:
            const menuItems: JSX.Element[] = [];
            if (isRedeployReady) {
                menuItems.push(redeployMenuItem);
            }

            menuItems.push(
                changeExpirationMenuItem,
                <Divider key="divider-1" />,
                deploymentLogsItem,
                moreInfoMenuItem,
                <Divider key="divider-2" />,
                deleteMenuItem
            );

            return menuItems;
        default:
            return [];
    }
};

export const getShowStatusImage = (state: EnvironmentProvisioningState): boolean => {
    switch (state) {
        case EnvironmentProvisioningState.Succeeded:
        case EnvironmentProvisioningState.Deploying:
        case EnvironmentProvisioningState.Updating:
            return true;
        default:
            return false;
    }
};

export const getUseTranslucentPreviewImage = (state: EnvironmentProvisioningState): boolean => {
    switch (state) {
        case EnvironmentProvisioningState.Creating:
        case EnvironmentProvisioningState.Deleting:
            return true;
        default:
            return false;
    }
};

export const getEnvironmentBannerViewModel = (
    error?: ResourceCardError,
    onSeeErrorDetails?: () => void
): ResourceCardBannerViewModel | undefined => {
    if (error) {
        return {
            kind: ResourceCardBannerKind.Error,
            error,
            isDismissable: false,
            onSeeErrorDetails,
        };
    }

    return undefined;
};
