import {
    makeStyles,
    mergeClasses,
    Spinner,
    tokens,
    Tree,
    TreeItem,
    TreeItemLayout,
    typographyStyles,
} from '@fluentui/react-components';
import {
    Checkmark20Regular,
    ErrorCircle20Regular,
    PresenceBlocked16Regular,
    QuestionCircle20Regular,
} from '@fluentui/react-icons';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { EnvironmentOperationStatus, EnvironmentOperationViewModel } from '../models';
import { CustomizedTreeHeader } from '../../../components/common/customized-tree-header';
import { getLocalizationConfiguration } from '../../../language/languages';
import { useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import { convertDateToLocaleString } from '../../../utilities/time';

interface DeploymentLogsDetailsProps {
    date: string;
    operations: EnvironmentOperationViewModel[];
}

interface DeploymentLogsHeaderProps {
    status: string;
    name: string;
    startTime: Date | undefined;
    endTime: Date | undefined;
    url: string | undefined;
    fileName: string | undefined;
}

interface DeploymentLogsItemProps {
    id: string;
    status: string;
    creator: string;
    startTime: string;
    endTime: string;
}

interface TaskIconProps {
    status: string;
}

/**
 * Styles
 */

const useIconStyles = makeStyles({
    default: {
        color: tokens.colorNeutralForeground3,
    },
    error: {
        color: tokens.colorStatusDangerForeground1,
    },
    success: {
        color: tokens.colorStatusSuccessForeground1,
    },
});

const useItemStyles = makeStyles({
    root: {
        display: 'flex',
        flexFlow: 'column',
        gap: '2px',
    },
    dateLabel: {
        ...typographyStyles.subtitle2,
        paddingBottom: '4px',
    },
    content: {
        paddingTop: '6px',
        paddingBottom: '6px',
        gap: '2px',
    },
});

/**
 * End Styles
 */

const TaskIcon: React.FC<TaskIconProps> = React.memo((props: TaskIconProps) => {
    const { status } = props;

    const iconStyles = useIconStyles();

    switch (status) {
        case EnvironmentOperationStatus.Running:
            return <Spinner aria-label={'spinner'} size="tiny" />;
        case EnvironmentOperationStatus.Succeeded:
            return <Checkmark20Regular className={iconStyles.success} />;
        case EnvironmentOperationStatus.Failed:
            return <ErrorCircle20Regular className={iconStyles.error} />;
        case EnvironmentOperationStatus.Canceled:
        case EnvironmentOperationStatus.NotStarted:
            return <PresenceBlocked16Regular className={iconStyles.default} />;
        default:
            return <QuestionCircle20Regular className={iconStyles.default} />;
    }
});

const DeploymentLogsHeader: React.FC<DeploymentLogsHeaderProps> = (props: DeploymentLogsHeaderProps) => {
    const { status, name, startTime, endTime, url, fileName } = props;

    const getStatusIcon = React.useMemo(() => {
        return <TaskIcon status={status} />;
    }, [status]);

    return (
        <CustomizedTreeHeader
            statusIcon={getStatusIcon}
            name={name}
            startTime={startTime}
            endTime={endTime}
            logUrl={url}
            logFileName={fileName}
            alwaysShowDownloadIcon
        />
    );
};

const DeploymentLogsItem: React.FC<DeploymentLogsItemProps> = (props: DeploymentLogsItemProps) => {
    const { id, status, creator, startTime, endTime } = props;

    // Style hooks
    const itemStyles = useItemStyles();
    const stackStyles = useStackWithFullWidthItemStyles();

    return (
        <TreeItem itemType="leaf" aria-roledescription="Operation details">
            <TreeItemLayout>
                <div className={mergeClasses(stackStyles.root, itemStyles.content)}>
                    <div className={mergeClasses(stackStyles.item)}>
                        <FormattedMessage
                            id="DeploymentLogsPanel_OperationParameterName_Id"
                            defaultMessage="Operation ID: {id}"
                            description="Parameter message showing the uniqe ID of the environment operation"
                            values={{ id }}
                        />
                    </div>
                    <div className={stackStyles.item}>
                        <FormattedMessage
                            id="DeploymentLogsPanel_OperationParameterName_Status"
                            defaultMessage="Status: {status}"
                            description="Parameter message showing the status of the environment operation"
                            values={{ status }}
                        />
                    </div>
                    <div className={stackStyles.item}>
                        <FormattedMessage
                            id="DeploymentLogsPanel_OperationParameterName_CreatedBy"
                            defaultMessage="Created by: {creator}"
                            description="Parameter message showing who initiated the environment operation"
                            values={{ creator }}
                        />
                    </div>
                    <div className={stackStyles.item}>
                        <FormattedMessage
                            id="DeploymentLogsPanel_OperationParameterName_Start"
                            defaultMessage="Start: {startTime}"
                            description="Parameter message showing when the environment operation started"
                            values={{ startTime }}
                        />
                    </div>
                    <div className={stackStyles.item}>
                        <FormattedMessage
                            id="DeploymentLogsPanel_OperationParameterName_End"
                            defaultMessage="End: {endTime}"
                            description="Parameter message showing when the environment operation completed"
                            values={{ endTime }}
                        />
                    </div>
                </div>
            </TreeItemLayout>
        </TreeItem>
    );
};

export const DeploymentLogsDetails: React.FC<DeploymentLogsDetailsProps> = (props: DeploymentLogsDetailsProps) => {
    const { date, operations } = props;

    const { locale } = getLocalizationConfiguration();

    // Style hooks
    const itemStyles = useItemStyles();
    const stackStyles = useStackWithFullWidthItemStyles();

    const branchList = React.useMemo(() => {
        return operations
            ? operations.map((operation) => (
                  <TreeItem
                      itemType="branch"
                      aria-roledescription="List of operations with aside content containing duration time and downloadable log icon"
                      /* eslint-disable @typescript-eslint/no-non-null-assertion */
                      // Justification: operation.operationId should always be defined, this is just a spec artifact
                      key={operation.operationId!}
                      /* eslint-disable @typescript-eslint/no-non-null-assertion */
                  >
                      <DeploymentLogsHeader
                          status={operation.status}
                          name={operation.kind}
                          startTime={operation.startTime}
                          endTime={operation.endTime}
                          url={operation.logBlobUri}
                          fileName={operation.operationId}
                      />
                      <Tree>
                          <DeploymentLogsItem
                              id={operation.operationId}
                              status={operation.status}
                              creator={operation.createdBy}
                              startTime={convertDateToLocaleString(operation.startTime, locale)}
                              endTime={convertDateToLocaleString(operation.endTime, locale)}
                          />
                      </Tree>
                  </TreeItem>
              ))
            : [];
    }, [operations]);

    return (
        <div className={mergeClasses(stackStyles.item, itemStyles.root)} key={date}>
            <div className={itemStyles.dateLabel}>{date}</div>
            <Tree aria-label="Environment operations">{branchList}</Tree>
        </div>
    );
};
