import { UnionMap, UnionValueMap } from '../types/union-map';
import { Method } from './http';

type DependencyKindPrefix = 'ARM' | 'DATAPLANE' | 'GRAPH' | 'IDENTITY';

const DependencyKindPrefix: UnionMap<DependencyKindPrefix> = {
    ARM: 'ARM',
    DATAPLANE: 'DATAPLANE',
    GRAPH: 'GRAPH',
    IDENTITY: 'IDENTITY',
};

export type AzureResourceManagerDependencyKind =
    | 'Batch'
    | 'GetProjectEnvironmentTypePermissions'
    | 'GetProjectPermissions'
    | 'ListLocations'
    | 'ListProjectEnvironmentTypes'
    | 'ListTenants'
    | 'Resources';

export const AzureResourceManagerDependencyKind: UnionMap<AzureResourceManagerDependencyKind> = {
    Batch: 'Batch',
    GetProjectEnvironmentTypePermissions: 'GetProjectEnvironmentTypePermissions',
    GetProjectPermissions: 'GetProjectPermissions',
    ListLocations: 'ListLocations',
    ListProjectEnvironmentTypes: 'ListProjectEnvironmentTypes',
    ListTenants: 'ListTenants',
    Resources: 'Resources',
};

export const AzureResourceManagerDependencyKindValues: UnionValueMap<AzureResourceManagerDependencyKind, string> = {
    Batch: `[${DependencyKindPrefix.ARM}] ${Method.POST} /BATCH`,
    GetProjectEnvironmentTypePermissions: `[${DependencyKindPrefix.ARM}] ${Method.GET} /SUBSCRIPTIONS/{SUBSCRIPTION}/RESOURCEGROUPS/{RESOURCEGROUP}/PROVIDERS/MICROSOFT.DEVCENTER/PROJECTS/{PROJECT}/ENVIRONMENTTYPES/{ENVIRONMENTTYPE}/PROVIDERS/MICROSOFT.AUTHORIZATION/PERMISSIONS`,
    GetProjectPermissions: `[${DependencyKindPrefix.ARM}] ${Method.GET} /SUBSCRIPTIONS/{SUBSCRIPTION}/RESOURCEGROUPS/{RESOURCEGROUP}/PROVIDERS/MICROSOFT.DEVCENTER/PROJECTS/{PROJECT}/PROVIDERS/MICROSOFT.AUTHORIZATION/PERMISSIONS`,
    ListLocations: `[${DependencyKindPrefix.ARM}] ${Method.GET} /SUBSCRIPTIONS/{SUBSCRIPTION}/RESOURCEGROUPS/{RESOURCEGROUP}/LOCATIONS`,
    ListProjectEnvironmentTypes: `[${DependencyKindPrefix.ARM}] ${Method.GET} /SUBSCRIPTIONS/{SUBSCRIPTION}/RESOURCEGROUPS/{RESOURCEGROUP}/PROVIDERS/MICROSOFT.DEVCENTER/PROJECTS/{PROJECT}/ENVIRONMENTTYPES/{ENVIRONMENTTYPE}`,
    ListTenants: `[${DependencyKindPrefix.ARM}] ${Method.GET} /TENANTS`,
    Resources: `[${DependencyKindPrefix.ARM}] ${Method.POST} /PROVIDERS/MICROSOFT.RESOURCEGRAPH/RESOURCES`,
};

export type DataPlaneDependencyKind =
    | 'CreateDevBox'
    | 'CreateOrReplaceEnvironment'
    | 'CreateCustomizationGroup'
    | 'DelayAllDevBoxActions'
    | 'DelayDevBoxAction'
    | 'DeleteDevBox'
    | 'DeleteEnvironment'
    | 'GetCatalog'
    | 'GetCustomizationGroup'
    | 'GetCustomizationTaskDefinition'
    | 'GetCustomizationTaskLog'
    | 'GetDevBox'
    | 'GetDevBoxAction'
    | 'GetDevBoxOperation'
    | 'GetEnvironment'
    | 'GetEnvironmentDefinition'
    | 'GetEnvironmentOperation'
    | 'GetEnvironmentOperationLogs'
    | 'GetPool'
    | 'GetProject'
    | 'GetRemoteConnection'
    | 'GetSchedule'
    | 'HeadDevCenter'
    | 'ListCatalogs'
    | 'ListCustomizationGroups'
    | 'ListCustomizationTaskDefinitions'
    | 'ListDevBoxActions'
    | 'ListDevBoxes'
    | 'ListDevBoxOperations'
    | 'ListEnvironmentDefinitions'
    | 'ListEnvironmentOperations'
    | 'ListEnvironments'
    | 'ListEnvironmentTypes'
    | 'ListPools'
    | 'ListProjects'
    | 'ListSchedulesByProject'
    | 'RepairDevBox'
    | 'RestartDevBox'
    | 'SkipDevBoxAction'
    | 'StartDevBox'
    | 'StopDevBox'
    | 'UpdateEnvironment'
    | 'ValidateCustomizationTasks';

export const DataPlaneDependencyKind: UnionMap<DataPlaneDependencyKind> = {
    CreateDevBox: 'CreateDevBox',
    CreateOrReplaceEnvironment: 'CreateOrReplaceEnvironment',
    CreateCustomizationGroup: 'CreateCustomizationGroup',
    DelayAllDevBoxActions: 'DelayAllDevBoxActions',
    DelayDevBoxAction: 'DelayDevBoxAction',
    DeleteDevBox: 'DeleteDevBox',
    DeleteEnvironment: 'DeleteEnvironment',
    GetCatalog: 'GetCatalog',
    GetCustomizationGroup: 'GetCustomizationGroup',
    GetCustomizationTaskDefinition: 'GetCustomizationTaskDefinition',
    GetCustomizationTaskLog: 'GetCustomizationTaskLog',
    GetDevBox: 'GetDevBox',
    GetDevBoxAction: 'GetDevBoxAction',
    GetDevBoxOperation: 'GetDevBoxOperation',
    GetEnvironment: 'GetEnvironment',
    GetEnvironmentDefinition: 'GetEnvironmentDefinition',
    GetEnvironmentOperation: 'GetEnvironmentOperation',
    GetEnvironmentOperationLogs: 'GetEnvironmentOperationLogs',
    GetPool: 'GetPool',
    GetProject: 'GetProject',
    GetRemoteConnection: 'GetRemoteConnection',
    GetSchedule: 'GetSchedule',
    HeadDevCenter: 'HeadDevCenter',
    ListCatalogs: 'ListCatalogs',
    ListCustomizationGroups: 'ListCustomizationGroups',
    ListCustomizationTaskDefinitions: 'ListCustomizationTaskDefinitions',
    ListDevBoxActions: 'ListDevBoxActions',
    ListDevBoxes: 'ListDevBoxes',
    ListDevBoxOperations: 'ListDevBoxOperations',
    ListEnvironmentDefinitions: 'ListEnvironmentDefinitions',
    ListEnvironmentOperations: 'ListEnvironmentOperations',
    ListEnvironments: 'ListEnvironments',
    ListEnvironmentTypes: 'ListEnvironmentTypes',
    ListPools: 'ListPools',
    ListProjects: 'ListProjects',
    ListSchedulesByProject: 'ListSchedulesByProject',
    RepairDevBox: 'RepairDevBox',
    RestartDevBox: 'RestartDevBox',
    SkipDevBoxAction: 'SkipDevBoxAction',
    StartDevBox: 'StartDevBox',
    StopDevBox: 'StopDevBox',
    UpdateEnvironment: 'UpdateEnvironment',
    ValidateCustomizationTasks: 'ValidateCustomizationTasks',
};

export const DataPlaneDependencyKindValues: UnionValueMap<DataPlaneDependencyKind, string> = {
    CreateDevBox: `[${DependencyKindPrefix.DATAPLANE}] ${Method.PUT} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES/{DEVBOX}`,
    CreateOrReplaceEnvironment: `[${DependencyKindPrefix.DATAPLANE}] ${Method.PUT} /PROJECTS/{PROJECT}/USERS/{USER}/ENVIRONMENTS/{ENVIRONMENT}`,
    CreateCustomizationGroup: `[${DependencyKindPrefix.DATAPLANE}] ${Method.PUT} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES/{DEVBOX}/CUSTOMIZATIONGROUPS/{CUSTOMIZATIONGROUP}`,
    DelayAllDevBoxActions: `[${DependencyKindPrefix.DATAPLANE}] ${Method.POST} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES/{DEVBOX}/ACTIONS:DELAY`,
    DelayDevBoxAction: `[${DependencyKindPrefix.DATAPLANE}] ${Method.POST} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES/{DEVBOX}/ACTIONS/{ACTION}:DELAY`,
    DeleteDevBox: `[${DependencyKindPrefix.DATAPLANE}] ${Method.DELETE} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES/{DEVBOX}`,
    DeleteEnvironment: `[${DependencyKindPrefix.DATAPLANE}] ${Method.DELETE} /PROJECTS/{PROJECT}/USERS/{USER}/ENVIRONMENTS/{ENVIRONMENT}`,
    GetCatalog: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/CATALOGS/{CATALOG}`,
    GetCustomizationGroup: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES/{DEVBOX}/CUSTOMIZATIONGROUPS/{CUSTOMIZATIONGROUP}`,
    GetCustomizationTaskDefinition: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/CATALOGS/{CATALOG}/CUSTOMIZATIONTASKS/{CUSTOMIZATIONTASK}`,
    GetCustomizationTaskLog: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES/{DEVBOX}/CUSTOMIZATIONGROUPS/{CUSTOMIZATIONGROUP}/logs/{TASK}`,
    GetDevBox: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES/{DEVBOX}`,
    GetDevBoxAction: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES/{DEVBOX}/ACTIONS/{ACTION}`,
    GetDevBoxOperation: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES/{DEVBOX}/OPERATIONS/{OPERATION}`,
    GetEnvironment: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/USERS/{USER}/ENVIRONMENTS/{ENVIRONMENT}`,
    GetEnvironmentDefinition: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/CATALOGS/{CATALOG}/ENVIRONMENTDEFINITIONS/{ENVIRONMENTDEFINITION}`,
    GetEnvironmentOperation: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/USERS/{USER}/ENVIRONMENTS/{ENVIRONMENT}/OPERATIONS/{OPERATION}`,
    GetEnvironmentOperationLogs: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/USERS/{USER}/ENVIRONMENTS/{ENVIRONMENT}/OPERATIONS/{OPERATION}/LOGS`,
    GetPool: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/POOLS/{POOL}`,
    GetProject: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}`,
    GetRemoteConnection: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES/{DEVBOX}/REMOTECONNECTION`,
    GetSchedule: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/POOLS/{POOL}/SCHEDULES/{SCHEDULE}`,
    HeadDevCenter: `[${DependencyKindPrefix.DATAPLANE}] ${Method.HEAD}`,
    ListCatalogs: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/CATALOGS`,
    ListCustomizationGroups: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES/{DEVBOX}/CUSTOMIZATIONGROUPS`,
    ListCustomizationTaskDefinitions: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/CUSTOMIZATIONTASKS`,
    ListDevBoxActions: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES/{DEVBOX}/ACTIONS`,
    ListDevBoxes: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES`,
    ListDevBoxOperations: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES/{DEVBOX}/OPERATIONS`,
    ListEnvironmentDefinitions: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/ENVIRONMENTDEFINITIONS`,
    ListEnvironmentOperations: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/ENVIRONMENTS/{ENVIRONMENT}/OPERATIONS`,
    ListEnvironments: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/ENVIRONMENTS`,
    ListEnvironmentTypes: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/ENVIRONMENTTYPES`,
    ListPools: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/POOLS`,
    ListProjects: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS`,
    ListSchedulesByProject: `[${DependencyKindPrefix.DATAPLANE}] ${Method.GET} /PROJECTS/{PROJECT}/SCHEDULES`,
    RepairDevBox: `[${DependencyKindPrefix.DATAPLANE}] ${Method.POST} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES/{DEVBOX}:REPAIR`,
    RestartDevBox: `[${DependencyKindPrefix.DATAPLANE}] ${Method.POST} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES/{DEVBOX}:RESTART`,
    SkipDevBoxAction: `[${DependencyKindPrefix.DATAPLANE}] ${Method.POST} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES/{DEVBOX}/ACTIONS/{ACTION}:SKIP`,
    StartDevBox: `[${DependencyKindPrefix.DATAPLANE}] ${Method.POST} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES/{DEVBOX}:START`,
    StopDevBox: `[${DependencyKindPrefix.DATAPLANE}] ${Method.POST} /PROJECTS/{PROJECT}/USERS/{USER}/DEVBOXES/{DEVBOX}:STOP`,
    UpdateEnvironment: `[${DependencyKindPrefix.DATAPLANE}] ${Method.PATCH} /PROJECTS/{PROJECT}/USERS/{USER}/ENVIRONMENTS/{ENVIRONMENT}`,
    ValidateCustomizationTasks: `[${DependencyKindPrefix.DATAPLANE}] ${Method.POST} /PROJECTS/{PROJECT}/CUSTOMIZATIONTASKS:VALIDATEGROUP`,
};

export type LoginMicrosoftOnlineDependencyKind = 'PostToken';

export const LoginMicrosoftOnlineDependencyKind: UnionMap<LoginMicrosoftOnlineDependencyKind> = {
    PostToken: 'PostToken',
};

export const LoginMicrosoftOnlineDependencyKindValues: UnionValueMap<LoginMicrosoftOnlineDependencyKind, string> = {
    PostToken: `[${DependencyKindPrefix.IDENTITY}] ${Method.POST} /{TENANT}/OAUTH2/V2.0/TOKEN`,
};

export type MicrosoftGraphDependencyKind =
    | 'GetBannerLogoForOrganization'
    | 'GetOrganization'
    | 'GetPhotoForSignedInUser'
    | 'GetSignedInUser'
    | 'ListDirectoryObjectsByIds';

export const MicrosoftGraphDependencyKind: UnionMap<MicrosoftGraphDependencyKind> = {
    GetBannerLogoForOrganization: 'GetBannerLogoForOrganization',
    GetOrganization: 'GetOrganization',
    GetPhotoForSignedInUser: 'GetPhotoForSignedInUser',
    GetSignedInUser: 'GetSignedInUser',
    ListDirectoryObjectsByIds: 'ListDirectoryObjectsByIds',
};

export const MicrosoftGraphDependencyKindValues: UnionValueMap<MicrosoftGraphDependencyKind, string> = {
    GetBannerLogoForOrganization: `[${DependencyKindPrefix.GRAPH}] ${Method.GET} /ORGANIZATION/{ORGANIZATION}/BRANDING/LOCALIZATIONS/DEFAULT/BANNERLOGO`,
    GetOrganization: `[${DependencyKindPrefix.GRAPH}] ${Method.GET} /ORGANIZATION/{ORGANIZATION}`,
    GetPhotoForSignedInUser: `[${DependencyKindPrefix.GRAPH}] ${Method.GET} /ME/PHOTO/\$VALUE`,
    GetSignedInUser: `[${DependencyKindPrefix.GRAPH}] ${Method.GET} /ME`,
    ListDirectoryObjectsByIds: `[${DependencyKindPrefix.GRAPH}] ${Method.POST} /DIRECTORYOBJECTS/GETBYIDS`,
};

export type DependencyKind =
    | AzureResourceManagerDependencyKind
    | DataPlaneDependencyKind
    | LoginMicrosoftOnlineDependencyKind
    | MicrosoftGraphDependencyKind;

export const DependencyKind: UnionMap<DependencyKind> = {
    ...AzureResourceManagerDependencyKind,
    ...DataPlaneDependencyKind,
    ...LoginMicrosoftOnlineDependencyKind,
    ...MicrosoftGraphDependencyKind,
};

export const DependencyKindValues: UnionValueMap<DependencyKind, string> = {
    ...AzureResourceManagerDependencyKindValues,
    ...DataPlaneDependencyKindValues,
    ...LoginMicrosoftOnlineDependencyKindValues,
    ...MicrosoftGraphDependencyKindValues,
};

export type DependencyType = 'BatchedFetch' | 'Fetch';

export const DependencyType: UnionMap<DependencyType> = {
    BatchedFetch: 'BatchedFetch',
    Fetch: 'Fetch',
};

export type EventName =
    | 'CannotCreateResourcesElementIncorrectlyInvoked'
    | 'DevBoxInFailedStateWithNoError'
    | 'EnvironmentInFailedStateWithNoError'
    | 'NoDevBoxCreatePermissions'
    | 'NoEnvironmentCreatePermissions'
    | 'ONLINE_STATUS_CHANGE'
    | 'RecordTenantIdAndHomeTenantId';

export const EventName: UnionMap<EventName> = {
    CannotCreateResourcesElementIncorrectlyInvoked: 'CannotCreateResourcesElementIncorrectlyInvoked',
    DevBoxInFailedStateWithNoError: 'DevBoxInFailedStateWithNoError',
    EnvironmentInFailedStateWithNoError: 'EnvironmentInFailedStateWithNoError',
    NoDevBoxCreatePermissions: 'NoDevBoxCreatePermissions',
    NoEnvironmentCreatePermissions: 'NoEnvironmentCreatePermissions',
    ONLINE_STATUS_CHANGE: 'ONLINE_STATUS_CHANGE',
    RecordTenantIdAndHomeTenantId: 'RecordTenantIdAndHomeTenantId',
};

export type PerformanceMetric =
    | 'AddDevBox'
    | 'AddEnvironment'
    | 'CreateCustomizationGroup'
    | 'DelayDevBoxAction'
    | 'DeleteDevBox'
    | 'DeleteEnvironment'
    | 'DeployEnvironment'
    | 'DiscoverDevBoxesInTenant'
    | 'DiscoverEnvironmentDefinitionsForEnvironments'
    | 'DiscoverEnvironmentDefinitionsForProjects'
    | 'DiscoverEnvironmentsInTenant'
    | 'DiscoverLocations'
    | 'DiscoverPermissionsForProjectEnvironmentTypes'
    | 'DiscoverPermissionsForProjects'
    | 'DiscoverPoolsForProjects'
    | 'DiscoverPoolsForDevBoxes'
    | 'DiscoverProjectEnvironmentTypes'
    | 'DiscoverProjectEnvironmentTypesAbilities'
    | 'DiscoverProjectEnvironmentTypesFromDataPlane'
    | 'DiscoverSchedulesForDevBoxes'
    | 'DiscoverSchedulesForPools'
    | 'DiscoveryForHome'
    | 'HibernateDevBox'
    | 'InitializeHome'
    | 'LoadAddDevBoxFormContent'
    | 'LoadAddEnvironmentFormContent'
    | 'LoadBackgroundResourcesForHome'
    | 'LoadControlPlaneResourcesForHome'
    | 'LoadCustomizationGroups'
    | 'LoadDevBoxCardContent'
    | 'LoadEnvironmentCardContent'
    | 'LoadRemoteConnectionsForDevBoxes'
    | 'LoadResourcesForHome'
    | 'LoadRegionRecommendations'
    | 'LoadDevBoxActionsForDevBoxes'
    | 'LoadDevBoxOperationsForDevBoxes'
    | 'LoadSecondaryDevBoxCardContent'
    | 'ShutdownDevBox'
    | 'StartDevBox'
    | 'StopDevBox'
    | 'RestartDevBox'
    | 'ResumeDevBox'
    | 'RepairDevBox'
    | 'RestoreSnapshot'
    | 'WarmAllDevCenterNameRecords'
    | 'DelayAllDevBoxActions'
    | 'SkipAllDevBoxActions';

export const PerformanceMetric: UnionMap<PerformanceMetric> = {
    AddDevBox: 'AddDevBox',
    AddEnvironment: 'AddEnvironment',
    CreateCustomizationGroup: 'CreateCustomizationGroup',
    DelayDevBoxAction: 'DelayDevBoxAction',
    DeleteDevBox: 'DeleteDevBox',
    DeleteEnvironment: 'DeleteEnvironment',
    DeployEnvironment: 'DeployEnvironment',
    DiscoverDevBoxesInTenant: 'DiscoverDevBoxesInTenant',
    DiscoverEnvironmentDefinitionsForEnvironments: 'DiscoverEnvironmentDefinitionsForEnvironments',
    DiscoverEnvironmentDefinitionsForProjects: 'DiscoverEnvironmentDefinitionsForProjects',
    DiscoverEnvironmentsInTenant: 'DiscoverEnvironmentsInTenant',
    DiscoverLocations: 'DiscoverLocations',
    DiscoverPermissionsForProjectEnvironmentTypes: 'DiscoverPermissionsForProjectEnvironmentTypes',
    DiscoverPermissionsForProjects: 'DiscoverPermissionsForProjects',
    DiscoverPoolsForProjects: 'DiscoverPoolsForProjects',
    DiscoverPoolsForDevBoxes: 'DiscoverPoolsForDevBoxes',
    DiscoverProjectEnvironmentTypes: 'DiscoverProjectEnvironmentTypes',
    DiscoverProjectEnvironmentTypesAbilities: 'DiscoverProjectEnvironmentTypesAbilities',
    DiscoverProjectEnvironmentTypesFromDataPlane: 'DiscoverProjectEnvironmentTypesFromDataPlane',
    DiscoverSchedulesForDevBoxes: 'DiscoverSchedulesForDevBoxes',
    DiscoverSchedulesForPools: 'DiscoverSchedulesForPools',
    DiscoveryForHome: 'DiscoveryForHome',
    HibernateDevBox: 'HibernateDevBox',
    InitializeHome: 'InitializeHome',
    LoadAddDevBoxFormContent: 'LoadAddDevBoxFormContent',
    LoadAddEnvironmentFormContent: 'LoadAddEnvironmentFormContent',
    LoadBackgroundResourcesForHome: 'LoadBackgroundResourcesForHome',
    LoadControlPlaneResourcesForHome: 'LoadControlPlaneResourcesForHome',
    LoadCustomizationGroups: 'LoadCustomizationGroups',
    LoadDevBoxCardContent: 'LoadDevBoxCardContent',
    LoadEnvironmentCardContent: 'LoadEnvironmentCardContent',
    LoadRemoteConnectionsForDevBoxes: 'LoadRemoteConnectionsForDevBoxes',
    LoadResourcesForHome: 'LoadResourcesForHome',
    LoadRegionRecommendations: 'LoadRegionRecommendations',
    LoadDevBoxActionsForDevBoxes: 'LoadDevBoxActionsForDevBoxes',
    LoadDevBoxOperationsForDevBoxes: 'LoadDevBoxOperationsForDevBoxes',
    LoadSecondaryDevBoxCardContent: 'LoadSecondaryDevBoxCardContent',
    ResumeDevBox: 'ResumeDevBox',
    ShutdownDevBox: 'ShutdownDevBox',
    StartDevBox: 'StartDevBox',
    StopDevBox: 'StopDevBox',
    RestartDevBox: 'RestartDevBox',
    RestoreSnapshot: 'RestoreSnapshot',
    RepairDevBox: 'RepairDevBox',
    WarmAllDevCenterNameRecords: 'WarmAllDevCenterNameRecords',
    DelayAllDevBoxActions: 'DelayAllDevBoxActions',
    SkipAllDevBoxActions: 'SkipAllDevBoxActions',
};

export type Metric =
    | PerformanceMetric
    | 'DirectLaunchStarted'
    | 'EnvironmentLogsDownloaded'
    | 'GetAccessToken'
    | 'HelpMenuPanelTroubleShootLinkClicked'
    | 'HelpMenuPanelInternalSupportLinkClicked'
    | 'HelpMenuPanelExternalSupportLinkClicked'
    | 'HelpMenuPanelDevHomeLinkClicked'
    | 'HelpMenuPanelMultiMonitorLinkClicked'
    | 'HelpMenuPanelDevBoxFeedbackLinkClicked'
    | 'HelpMenuPanelAdeFeedbackLinkClicked'
    | 'HelpMenuPanelPrivacyLinkClicked'
    | 'HelpMenuPanelProductTermsLinkClicked'
    | 'HelpMenuPanelOneESDevBoxFaqLinkClicked'
    | 'HelpMenuPanelWindowsAppLinkClicked'
    | 'InstallRemoteClientLinkClicked'
    | 'RecommendedRegionUsed'
    | 'RecommendedRegionNotUsed'
    | 'SupportPanelTroubleShootLinkClicked'
    | 'SupportPanelInternalSupportLinkClicked'
    | 'SupportPanelExternalSupportLinkClicked'
    | 'UserSettingsPanelSubmitted'
    | 'WelcomeTourContinued'
    | 'WelcomeTourSkipped'
    | 'WelcomeTourContinuedWithNoShowAgain'
    | 'WelcomeTourSkippedWithNoShowAgain';

export const Metric: UnionMap<Metric> = {
    ...PerformanceMetric,
    DirectLaunchStarted: 'DirectLaunchStarted',
    EnvironmentLogsDownloaded: 'EnvironmentLogsDownloaded',
    GetAccessToken: 'GetAccessToken',
    HelpMenuPanelTroubleShootLinkClicked: 'HelpMenuPanelTroubleShootLinkClicked',
    HelpMenuPanelInternalSupportLinkClicked: 'HelpMenuPanelInternalSupportLinkClicked',
    HelpMenuPanelExternalSupportLinkClicked: 'HelpMenuPanelExternalSupportLinkClicked',
    HelpMenuPanelDevHomeLinkClicked: 'HelpMenuPanelDevHomeLinkClicked',
    HelpMenuPanelMultiMonitorLinkClicked: 'HelpMenuPanelMultiMonitorLinkClicked',
    HelpMenuPanelDevBoxFeedbackLinkClicked: 'HelpMenuPanelDevBoxFeedbackLinkClicked',
    HelpMenuPanelAdeFeedbackLinkClicked: 'HelpMenuPanelAdeFeedbackLinkClicked',
    HelpMenuPanelPrivacyLinkClicked: 'HelpMenuPanelPrivacyLinkClicked',
    HelpMenuPanelProductTermsLinkClicked: 'HelpMenuPanelProductTermsLinkClicked',
    HelpMenuPanelOneESDevBoxFaqLinkClicked: 'HelpMenuPanelOneESDevBoxFaqLinkClicked',
    HelpMenuPanelWindowsAppLinkClicked: 'HelpMenuPanelWindowsAppLinkClicked',
    InstallRemoteClientLinkClicked: 'InstallRemoteClientLinkClicked',
    RecommendedRegionUsed: 'RecommendedRegionUsed',
    RecommendedRegionNotUsed: 'RecommendedRegionNotUsed',
    SupportPanelTroubleShootLinkClicked: 'SupportPanelTroubleShootLinkClicked',
    SupportPanelInternalSupportLinkClicked: 'SupportPanelInternalSupportLinkClicked',
    SupportPanelExternalSupportLinkClicked: 'SupportPanelExternalSupportLinkClicked',
    UserSettingsPanelSubmitted: 'UserSettingsPanelSubmitted',
    WelcomeTourContinued: 'WelcomeTourContinued',
    WelcomeTourSkipped: 'WelcomeTourSkipped',
    WelcomeTourContinuedWithNoShowAgain: 'WelcomeTourContinuedWithNoShowAgain',
    WelcomeTourSkippedWithNoShowAgain: 'WelcomeTourSkippedWithNoShowAgain',
};

type PropertyName =
    | 'ActivityId'
    | 'ClientRequestId'
    | 'DevCenter'
    | 'EnvironmentType'
    | 'ErrorCode'
    | 'ErrorCodes'
    | 'FeatureFlags'
    | 'HomeTenantId'
    | 'Hostname'
    | 'InnerStack'
    | 'IsInIframe'
    | 'IsRedirecting'
    | 'IsUsingFallbackLocalization'
    | 'Kind'
    | 'Language'
    | 'Locale'
    | 'Market'
    | 'Method'
    | 'Outcome'
    | 'Pathname'
    | 'PortalHostname'
    | 'PortalPathname'
    | 'PortalSearch'
    | 'Search'
    | 'ServerOperationId'
    | 'Service'
    | 'Severity'
    | 'SeverityLevel'
    | 'Success'
    | 'TenantId'
    | 'TruncatedProps'
    | 'Username'
    | 'UserObjectId'
    | 'Version'
    | 'MultiMonitor'
    | 'PreferredRemoteApp'
    | 'Link'
    | 'Region';

export type Property =
    | 'activityId'
    | 'clientRequestId'
    | 'devCenter'
    | 'environmentType'
    | 'errorCode'
    | 'errorCodes'
    | 'featureFlags'
    | 'homeTenantId'
    | 'hostname'
    | 'innerStack'
    | 'isInIframe'
    | 'isRedirecting'
    | 'isUsingFallbackLocalization'
    | 'kind'
    | 'language'
    | 'locale'
    | 'market'
    | 'method'
    | 'outcome'
    | 'pathname'
    | 'portalHostname'
    | 'portalPathname'
    | 'portalSearch'
    | 'search'
    | 'ServerOperationId'
    | 'Service'
    | 'severity'
    | 'severityLevel'
    | 'success'
    | 'tenantId'
    | 'truncatedProps'
    | 'username'
    | 'userObjectId'
    | 'version'
    | 'multiMonitor'
    | 'preferredRemoteApp'
    | 'link'
    | 'region';

export const Property: UnionValueMap<PropertyName, Property> = {
    ActivityId: 'activityId',
    ClientRequestId: 'clientRequestId',
    DevCenter: 'devCenter',
    EnvironmentType: 'environmentType',
    ErrorCode: 'errorCode',
    ErrorCodes: 'errorCodes',
    FeatureFlags: 'featureFlags',
    HomeTenantId: 'homeTenantId',
    Hostname: 'hostname',
    InnerStack: 'innerStack',
    IsInIframe: 'isInIframe',
    IsRedirecting: 'isRedirecting',
    IsUsingFallbackLocalization: 'isUsingFallbackLocalization',
    Kind: 'kind',
    Language: 'language',
    Locale: 'locale',
    Market: 'market',
    Method: 'method',
    Outcome: 'outcome',
    Pathname: 'pathname',
    PortalHostname: 'portalHostname',
    PortalPathname: 'portalPathname',
    PortalSearch: 'portalSearch',
    Search: 'search',
    ServerOperationId: 'ServerOperationId',
    Service: 'Service',
    Severity: 'severity',
    SeverityLevel: 'severityLevel',
    Success: 'success',
    TenantId: 'tenantId',
    TruncatedProps: 'truncatedProps',
    Username: 'username',
    UserObjectId: 'userObjectId',
    Version: 'version',
    MultiMonitor: 'multiMonitor',
    PreferredRemoteApp: 'preferredRemoteApp',
    Link: 'link',
    Region: 'region',
};

export type Severity = 'Critical' | 'Error' | 'Information' | 'Verbose' | 'Warning';

export const Severity: UnionMap<Severity> = {
    Critical: 'Critical',
    Error: 'Error',
    Information: 'Information',
    Verbose: 'Verbose',
    Warning: 'Warning',
};

export type TagName =
    | 'DeviceId'
    | 'DeviceType'
    | 'LocationIp'
    | 'OperationId'
    | 'OperationName'
    | 'SessionId'
    | 'SdkVersion'
    | 'UserId';

export const TagName: UnionMap<TagName> = {
    DeviceId: 'DeviceId',
    DeviceType: 'DeviceType',
    LocationIp: 'LocationIp',
    OperationId: 'OperationId',
    OperationName: 'OperationName',
    SdkVersion: 'SdkVersion',
    SessionId: 'SessionId',
    UserId: 'UserId',
};

export type Tag =
    | 'ai.device.id'
    | 'ai.device.type'
    | 'ai.internal.sdkVersion'
    | 'ai.location.ip'
    | 'ai.operation.id'
    | 'ai.operation.name'
    | 'ai.session.id'
    | 'ai.user.id';

export const Tag: UnionValueMap<TagName, Tag> = {
    DeviceId: 'ai.device.id',
    DeviceType: 'ai.device.type',
    LocationIp: 'ai.location.ip',
    OperationId: 'ai.operation.id',
    OperationName: 'ai.operation.name',
    SdkVersion: 'ai.internal.sdkVersion',
    SessionId: 'ai.session.id',
    UserId: 'ai.user.id',
};

export type TelemetryTypeName = 'Dependency' | 'Event' | 'Exception' | 'Metric' | 'Trace';

export const TelemetryTypeName: UnionMap<TelemetryTypeName> = {
    Dependency: 'Dependency',
    Event: 'Event',
    Exception: 'Exception',
    Metric: 'Metric',
    Trace: 'Trace',
};

export type TelemetryType = 'EventData' | 'ExceptionData' | 'MessageData' | 'MetricData' | 'RemoteDependencyData';

export const TelemetryType: UnionValueMap<TelemetryTypeName, TelemetryType> = {
    Dependency: 'RemoteDependencyData',
    Event: 'EventData',
    Exception: 'ExceptionData',
    Metric: 'MetricData',
    Trace: 'MessageData',
};
