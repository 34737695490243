import { Divider, makeStyles, mergeClasses, tokens, typographyStyles } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ShimmeredMetadataItem } from '../../../components/common/metadata/metadata-item';
import { ShimmeredMetadataItemWidth } from '../../../components/common/metadata/models';
import { GraphDirectoryObjectContract } from '../../../data/contracts/graph';
import { useDeploymentLogsPanelContext } from '../../../hooks/context/panels';
import { Status } from '../../../models/common';
import { EnvironmentOperation, EnvironmentOperationLogUri } from '../../../models/environment';
import {
    getEnvironmentOperationLogs,
    getEnvironmentOperationLogStatuses,
    getEnvironmentOperations,
} from '../../../redux/selector/environment-operation-selectors';
import { getGraphDirectoryObjects } from '../../../redux/selector/graph-selectors';
import { useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import { SerializableMap } from '../../../types/serializable-map';
import { FormPanel } from '../../common/form-panel';
import { DeploymentLogsDetails } from './deployment-logs-details';
import {
    areEnvironmentOperationsAndLogsReady,
    getEnvironmentOperationViewModels,
    getGroupedOperationsByDate,
} from './selectors';

export interface DeploymentLogsPanelProps {
    name: string;
    uri: string;
    isOpen: boolean;
    onDismiss: () => void;
}

export interface DeploymentLogsPanelComponentProps extends DeploymentLogsPanelProps {
    operations: EnvironmentOperation[];
    logs: SerializableMap<EnvironmentOperationLogUri>;
    directoryObjects: SerializableMap<GraphDirectoryObjectContract>;
    statuses: SerializableMap<Status>;
}

const messages = defineMessages({
    deploymentLogsPanelCloseButtonAriaLabel: {
        id: 'DeploymentLogsPanel_Close_AriaLabel',
        defaultMessage: 'Close deployment logs panel',
        description: 'Aria label for the deployment logs panel close button.',
    },
});

/**
 * Styles
 */

const useBodyStyles = makeStyles({
    root: {
        gap: '16px',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
    },
    divider: {
        paddingTop: '5px',
        paddingBottom: '4px',
    },
});

const useItemStyles = makeStyles({
    subTitleText: typographyStyles.subtitle2,
    infoText: {
        ...typographyStyles.caption1,
        color: tokens.colorNeutralForeground2,
    },
});

/**
 * END Styles
 */

const DeploymentLogsPanelComponent: React.FC<DeploymentLogsPanelComponentProps> = (
    props: DeploymentLogsPanelComponentProps
) => {
    const {
        name: environmentName,
        uri: environmentUri,
        operations,
        logs,
        directoryObjects,
        statuses,
        isOpen,
        onDismiss,
    } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const stackStyles = useStackWithFullWidthItemStyles();
    const bodyStyles = useBodyStyles();
    const itemStyles = useItemStyles();

    // Map to view models
    const environmentOperations = React.useMemo(
        () => getEnvironmentOperationViewModels(environmentUri, operations, logs, directoryObjects),
        [environmentUri, operations, logs, directoryObjects]
    );

    const infoText = React.useMemo(() => {
        return (
            <FormattedMessage
                id="DeploymentLogsPanel_OperationsCompleted_Count"
                defaultMessage="{operationsCount} operations completed"
                description="Number of operations in deployment logs"
                values={{ operationsCount: environmentOperations.length }}
            />
        );
    }, [environmentOperations.length]);

    // Format date into MM/DD/YY and group operations by date
    const groupedOperations = React.useMemo(
        () => getGroupedOperationsByDate(environmentOperations),
        [environmentOperations]
    );

    const operationUris = React.useMemo(
        () => environmentOperations.map((operation) => operation.operationUri),
        [environmentOperations]
    );

    const isShimmered = React.useMemo(
        () => !areEnvironmentOperationsAndLogsReady(operationUris, statuses),
        [operationUris, statuses]
    );

    return (
        <FormPanel
            isOpen={isOpen}
            onDismiss={onDismiss}
            closeButtonAriaLabel={formatMessage(messages.deploymentLogsPanelCloseButtonAriaLabel)}
            title={
                <FormattedMessage
                    id="DeploymentLogsPanel_Header_Text"
                    defaultMessage="Deployment Logs"
                    description="Header text for the deployment logs panel"
                />
            }
            isMediumSize
        >
            <div className={mergeClasses(stackStyles.root, bodyStyles.root)}>
                {/* Subheading (Environment name and number of operations) */}
                <div className={mergeClasses(stackStyles.item, bodyStyles.item)}>
                    <div className={itemStyles.subTitleText}>{environmentName}</div>
                    <div className={itemStyles.infoText}>{infoText}</div>
                </div>

                <Divider className={bodyStyles.divider} />

                {/* Displays shimmered component if operations and logs aren't ready */}
                {/* If they are ready, displays operations grouped by date */}
                {isShimmered ? (
                    <ShimmeredMetadataItem width={ShimmeredMetadataItemWidth.Large} />
                ) : (
                    groupedOperations.map(([date, operations]) => (
                        <DeploymentLogsDetails key={date} date={date} operations={operations} />
                    ))
                )}
            </div>
        </FormPanel>
    );
};

export const DeploymentLogsPanelContainer: React.FC = () => {
    // Context hooks
    const { closeSurface: closePanel, isOpen, properties } = useDeploymentLogsPanelContext();

    const operations = useSelector(getEnvironmentOperations);
    const logs = useSelector(getEnvironmentOperationLogs);
    const directoryObjects = useSelector(getGraphDirectoryObjects);
    const statuses = useSelector(getEnvironmentOperationLogStatuses);

    if (!isOpen) {
        return <></>;
    }

    return (
        <DeploymentLogsPanelComponent
            {...properties}
            operations={operations}
            logs={logs}
            directoryObjects={directoryObjects}
            statuses={statuses}
            isOpen={isOpen}
            onDismiss={closePanel}
        />
    );
};

export default DeploymentLogsPanelContainer;
