import { GraphDirectoryObjectContract } from '../../../data/contracts/graph';
import { createEnvironmentDataPlaneUri } from '../../../ids/environment';
import { getTokensFromEnvironmentOperationDataPlaneUri } from '../../../ids/environment-operation';
import { Status } from '../../../models/common';
import { EnvironmentOperation, EnvironmentOperationLogUri } from '../../../models/environment';
import { AsyncState } from '../../../redux/store/common-state';
import { KeyValuePair } from '../../../types/key-value-pair';
import { SerializableMap } from '../../../types/serializable-map';
import { groupAndReduceBy } from '../../../utilities/array';
import { get } from '../../../utilities/serializable-map';
import { getNumericDateStringFromDate } from '../../../utilities/time';
import { EnvironmentOperationViewModel } from '../models';

export const areEnvironmentOperationsAndLogsReady = (
    operationUris: string[],
    statuses: SerializableMap<Status>
): boolean => operationUris.every((uri) => get(statuses, uri)?.state === AsyncState.Success);

// Map to view model object
export const getEnvironmentOperationViewModels = (
    environmentUri: string,
    operations: EnvironmentOperation[],
    logs: SerializableMap<EnvironmentOperationLogUri>,
    directoryObjects: SerializableMap<GraphDirectoryObjectContract>
): EnvironmentOperationViewModel[] => {
    // Filter operations by specified environment URI
    const filteredOperations = operations.filter((operation) => {
        const { devCenter, environmentName, projectName, user } = getTokensFromEnvironmentOperationDataPlaneUri(
            operation.uri
        );
        return createEnvironmentDataPlaneUri({ devCenter, environmentName, projectName, user }) === environmentUri;
    });

    return filteredOperations.map((operation) => {
        const creator =
            operation.createdByObjectId !== undefined ? get(directoryObjects, operation.createdByObjectId) : undefined;

        const creatorName = creator?.displayName ?? operation.createdByObjectId ?? '';

        return {
            kind: operation.kind,
            operationUri: operation.uri,
            operationId: operation.operationId,
            status: operation.status,
            createdBy: creatorName,
            startTime: operation.startTime,
            endTime: operation.endTime,
            logBlobUri: get(logs, operation.uri)?.logBlobUri,
        };
    });
};

// Format date into MM/DD/YY and group operations by date
export const getGroupedOperationsByDate = (
    operations: EnvironmentOperationViewModel[]
): KeyValuePair<string, EnvironmentOperationViewModel[]>[] => {
    const key = (operation: EnvironmentOperationViewModel): string => {
        const options: Intl.DateTimeFormatOptions = {
            year: '2-digit',
        };

        return getNumericDateStringFromDate(operation.startTime, 'en-US', options) ?? '';
    };

    const reducer = (
        previousValue: EnvironmentOperationViewModel[],
        currentValue: EnvironmentOperationViewModel
    ): EnvironmentOperationViewModel[] => {
        return [...previousValue, currentValue];
    };

    const initialValue: EnvironmentOperationViewModel[] = [];

    return groupAndReduceBy(operations, key, reducer, initialValue);
};
