import { all, AllEffect, fork, ForkEffect } from 'redux-saga/effects';
import { getEnvironmentOperationLogListenerSaga } from './get-environment-operation-log';
import { listEnvironmentOperationsListenerSaga } from './list-environment-operations';
import { loadEnvironmentOperationsAndLogsListenerSaga } from './load-environment-operations-and-logs';

export function* rootSaga(): Generator<AllEffect<ForkEffect<void>>, void, unknown> {
    yield all([
        fork(getEnvironmentOperationLogListenerSaga),
        fork(listEnvironmentOperationsListenerSaga),
        fork(loadEnvironmentOperationsAndLogsListenerSaga),
    ]);
}
