import { Action, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { Severity } from '../../constants/telemetry';
import { TrackReduxActionOptions, trackReduxAction, trackTrace } from '../../utilities/telemetry/channel';
import { ApplicationActionType } from '../actions/application/application-actions';
import { isGroupedPayload, isPayloadAction } from '../actions/core-actions';
import { DevBoxActionActionType } from '../actions/dev-box-action/dev-box-action-actions';
import { DevBoxActionType } from '../actions/dev-box/dev-box-actions';
import { EnvironmentActionType } from '../actions/environment/environment-actions';
import { EnvironmentDefinitionActionType } from '../actions/environment-definition/environment-definition-actions';
import { EnvironmentOperationActionType } from '../actions/environment-operation/environment-operation-actions';
import { IdentityActionType } from '../actions/identity/identity-actions';
import { PermissionActionType } from '../actions/permission/permission-actions';
import { PoolActionType } from '../actions/pool/pool-actions';
import { ProjectEnvironmentTypeActionType } from '../actions/project-environment-type/project-environment-type-actions';
import { RemoteConnectionActionType } from '../actions/remote-connection/remote-connection-actions';
import { HomeActionType } from '../actions/sub-applications/home/home-actions';
import { SubscriptionActionType } from '../actions/subscription/subscription-actions';
import { TimeActionType } from '../actions/time/time-actions';
import { getActionsInGroup } from '../utilities/groupable-action';
import { getActivityId } from '../utilities/trackable-metadata';

const criticalSeverityActions: string[] = [
    ApplicationActionType.FatalError,
    DevBoxActionActionType.DelayAllDevBoxActionsError,
    DevBoxActionActionType.ListDevBoxActionsError,
    DevBoxActionActionType.SkipAllDevBoxActionsError,
    DevBoxActionType.AddDevBoxError,
    DevBoxActionType.DeleteDevBoxError,
    DevBoxActionType.HibernateDevBoxError,
    DevBoxActionType.ListDevBoxesError,
    DevBoxActionType.PollNonTerminalDevBoxError,
    DevBoxActionType.RestartDevBoxError,
    DevBoxActionType.RepairDevBoxError,
    DevBoxActionType.ShutdownDevBoxError,
    DevBoxActionType.StartDevBoxError,
    EnvironmentActionType.AddEnvironmentError,
    EnvironmentActionType.DeleteEnvironmentError,
    EnvironmentActionType.DeployEnvironmentError,
    EnvironmentActionType.ListEnvironmentsError,
    EnvironmentActionType.PollNonTerminalEnvironmentError,
    EnvironmentDefinitionActionType.ListEnvironmentDefinitionsError,
    EnvironmentOperationActionType.GetEnvironmentOperationLogError,
    EnvironmentOperationActionType.ListEnvironmentOperationsError,
    EnvironmentOperationActionType.LoadEnvironmentOperationsAndLogsError,
    HomeActionType.LoadControlPlaneResourcesForHomeError,
    PermissionActionType.GetProjectEnvironmentTypePermissionsError,
    PermissionActionType.GetProjectPermissionsError,
    PoolActionType.ListPoolsError,
    ProjectEnvironmentTypeActionType.ListProjectEnvironmentTypesError,
    RemoteConnectionActionType.GetRemoteConnectionError,
    SubscriptionActionType.ListLocationsError,
];

const errorSeverityActions: string[] = [
    DevBoxActionActionType.DelayAllDevBoxActionsFailed,
    DevBoxActionActionType.SkipAllDevBoxActionsFailed,
    DevBoxActionType.AddDevBoxFailed,
    DevBoxActionType.DeleteDevBoxFailed,
    DevBoxActionType.HibernateDevBoxFailed,
    DevBoxActionType.ListDevBoxesFailed,
    DevBoxActionType.RestartDevBoxFailed,
    DevBoxActionType.RepairDevBoxFailed,
    DevBoxActionType.ShutdownDevBoxFailed,
    DevBoxActionType.StartDevBoxFailed,
    EnvironmentActionType.AddEnvironmentFailed,
    EnvironmentActionType.DeleteEnvironmentFailed,
    EnvironmentActionType.DeployEnvironmentFailed,
    EnvironmentActionType.ListEnvironmentsFailed,
    EnvironmentDefinitionActionType.ListEnvironmentDefinitionsFailed,
    EnvironmentOperationActionType.GetEnvironmentOperationLogFailed,
    EnvironmentOperationActionType.ListEnvironmentOperationsFailed,
    EnvironmentOperationActionType.LoadEnvironmentOperationsAndLogsFailed,
    HomeActionType.LoadControlPlaneResourcesForHomeFailed,
    PermissionActionType.GetProjectEnvironmentTypePermissionsFailed,
    PermissionActionType.GetProjectPermissionsFailed,
    PoolActionType.ListPoolsFailed,
    ProjectEnvironmentTypeActionType.ListProjectEnvironmentTypesFailed,
    RemoteConnectionActionType.GetRemoteConnectionFailed,
    SubscriptionActionType.ListLocationsFailed,
];

const isCriticalSeverityAction = (type: string) => criticalSeverityActions.includes(type);

const isErrorSeverityAction = (type: string) => errorSeverityActions.includes(type);

const getSeverityForAction = (action: Action): Severity | undefined => {
    const { type } = action;

    if (isCriticalSeverityAction(type)) {
        return Severity.Critical;
    }

    if (isErrorSeverityAction(type)) {
        return Severity.Error;
    }

    return undefined;
};

export const ignoreActions = [
    IdentityActionType.GetAccessToken,
    IdentityActionType.GetAccessTokenSuccess,
    PermissionActionType.GetProjectEnvironmentTypePermissions,
    PermissionActionType.GetProjectEnvironmentTypePermissionsSuccess,
    PermissionActionType.GetProjectPermissions,
    PermissionActionType.GetProjectPermissionsSuccess,
    TimeActionType.UpdateDisplayTime,
];

const ignoreActionsSet = new Set<string>(ignoreActions);

const getTrackReduxActionOptions = (action: Action): TrackReduxActionOptions | undefined => {
    const activityId = getActivityId(action);
    const severity = getSeverityForAction(action);

    // Return undefined if there aren't values for any relevant fields
    if (!activityId && !severity) {
        return undefined;
    }

    return {
        ...(activityId ? { activityId } : {}),
        ...(severity ? { severity } : {}),
    };
};

const logAction = (action: Action): void => {
    const { type } = action;

    // If this is a grouped action, log each action inside it as its own piece of telemetry
    if (isPayloadAction(action) && isGroupedPayload(action.payload)) {
        const actions = getActionsInGroup(action);

        // Unexpected state: grouped action had empty actions array. Log a warning.
        if (actions.length < 1) {
            trackTrace(
                `Unexpected state: expected non-empty actions array in grouped ${type} action, but array contained 0 actions`,
                {
                    severity: Severity.Warning,
                }
            );
            return;
        }

        trackTrace(`Logging ${actions.length} ${type} actions from single group`, { severity: Severity.Information });
        actions.forEach((action) => logAction(action));

        return;
    }

    const options = getTrackReduxActionOptions(action);
    trackReduxAction(action, options);
};

export const actionLoggerMiddleware: Middleware =
    ({}: MiddlewareAPI) =>
    (next: Dispatch) =>
    (action: Action) => {
        const result = next(action);
        const { type } = action;

        // Skip logging if action is ignored
        if (!ignoreActionsSet.has(type)) {
            logAction(action);
        }

        return result;
    };

export default actionLoggerMiddleware;
