import {
    GetEnvironmentOperationLogResponse,
    ListEnvironmentOperationsResponse,
} from '../../../data/services/data-plane-api/environment-operation';
import { createActionCreator } from '../core-action-creators';
import { ErrorPayload, FailedPayload, IndexedPayload } from '../core-actions';
import {
    EnvironmentOperationActionType,
    GetEnvironmentOperationLogSuccessPayload,
    ListEnvironmentOperationsSuccessPayload,
} from './environment-operation-actions';

/**
 * Action creators
 */

export const getEnvironmentOperationLog = createActionCreator<IndexedPayload, GetEnvironmentOperationLogResponse>(
    EnvironmentOperationActionType.GetEnvironmentOperationLog,
    { async: true, startsActivity: true }
);

export const getEnvironmentOperationLogError = createActionCreator<ErrorPayload & IndexedPayload>(
    EnvironmentOperationActionType.GetEnvironmentOperationLogError
);

export const getEnvironmentOperationLogFailed = createActionCreator<FailedPayload & IndexedPayload>(
    EnvironmentOperationActionType.GetEnvironmentOperationLogFailed
);

export const getEnvironmentOperationLogSuccess = createActionCreator<GetEnvironmentOperationLogSuccessPayload>(
    EnvironmentOperationActionType.GetEnvironmentOperationLogSuccess
);

export const listEnvironmentOperations = createActionCreator<IndexedPayload, ListEnvironmentOperationsResponse>(
    EnvironmentOperationActionType.ListEnvironmentOperations,
    { async: true, startsActivity: true }
);

export const listEnvironmentOperationsError = createActionCreator<ErrorPayload & IndexedPayload>(
    EnvironmentOperationActionType.ListEnvironmentOperationsError
);

export const listEnvironmentOperationsFailed = createActionCreator<FailedPayload & IndexedPayload>(
    EnvironmentOperationActionType.ListEnvironmentOperationsFailed
);

export const listEnvironmentOperationsSuccess = createActionCreator<ListEnvironmentOperationsSuccessPayload>(
    EnvironmentOperationActionType.ListEnvironmentOperationsSuccess
);

export const loadEnvironmentOperationsAndLogs = createActionCreator<IndexedPayload>(
    EnvironmentOperationActionType.LoadEnvironmentOperationsAndLogs
);

export const loadEnvironmentOperationsAndLogsError = createActionCreator<ErrorPayload & IndexedPayload>(
    EnvironmentOperationActionType.LoadEnvironmentOperationsAndLogsError
);

export const loadEnvironmentOperationsAndLogsFailed = createActionCreator<FailedPayload & IndexedPayload>(
    EnvironmentOperationActionType.LoadEnvironmentOperationsAndLogsFailed
);

export const loadEnvironmentOperationsAndLogsSuccess = createActionCreator<IndexedPayload>(
    EnvironmentOperationActionType.LoadEnvironmentOperationsAndLogsSuccess
);
