import { EnvironmentOperation, EnvironmentOperationLogUri } from '../../../models/environment';
import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType, IndexedPayload, ResultPayload } from '../core-actions';
import {
    getEnvironmentOperationLog,
    listEnvironmentOperations,
    loadEnvironmentOperationsAndLogs,
} from './environment-operation-action-creators';

/**
 * Action types
 */

type EnvironmentOperationActionName =
    | ActionName<'GetEnvironmentOperationLog', 'Error' | 'Failed' | 'Success'>
    | ActionName<'ListEnvironmentOperations', 'Error' | 'Failed' | 'Success'>
    | ActionName<'LoadEnvironmentOperationsAndLogs', 'Error' | 'Failed' | 'Success'>;

export type EnvironmentOperationActionType =
    | ActionType<'GET_ENVIRONMENT_OPERATION_LOG', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LIST_ENVIRONMENT_OPERATIONS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LOAD_ENVIRONMENT_OPERATIONS_AND_LOGS', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const EnvironmentOperationActionType: UnionValueMap<
    EnvironmentOperationActionName,
    EnvironmentOperationActionType
> = {
    GetEnvironmentOperationLog: 'GET_ENVIRONMENT_OPERATION_LOG',
    GetEnvironmentOperationLogError: 'GET_ENVIRONMENT_OPERATION_LOG_ERROR',
    GetEnvironmentOperationLogFailed: 'GET_ENVIRONMENT_OPERATION_LOG_FAILED',
    GetEnvironmentOperationLogSuccess: 'GET_ENVIRONMENT_OPERATION_LOG_SUCCESS',
    ListEnvironmentOperations: 'LIST_ENVIRONMENT_OPERATIONS',
    ListEnvironmentOperationsError: 'LIST_ENVIRONMENT_OPERATIONS_ERROR',
    ListEnvironmentOperationsFailed: 'LIST_ENVIRONMENT_OPERATIONS_FAILED',
    ListEnvironmentOperationsSuccess: 'LIST_ENVIRONMENT_OPERATIONS_SUCCESS',
    LoadEnvironmentOperationsAndLogs: 'LOAD_ENVIRONMENT_OPERATIONS_AND_LOGS',
    LoadEnvironmentOperationsAndLogsError: 'LOAD_ENVIRONMENT_OPERATIONS_AND_LOGS_ERROR',
    LoadEnvironmentOperationsAndLogsFailed: 'LOAD_ENVIRONMENT_OPERATIONS_AND_LOGS_FAILED',
    LoadEnvironmentOperationsAndLogsSuccess: 'LOAD_ENVIRONMENT_OPERATIONS_AND_LOGS_SUCCESS',
};

/**
 * Payloads
 */

export type GetEnvironmentOperationLogSuccessPayload = IndexedPayload & ResultPayload<EnvironmentOperationLogUri>;

export type ListEnvironmentOperationsSuccessPayload = IndexedPayload & ResultPayload<EnvironmentOperation[]>;

/**
 * Action shorthands
 */

export type GetEnvironmentOperationLogAction = ReturnType<typeof getEnvironmentOperationLog>;

export type ListEnvironmentOperationsAction = ReturnType<typeof listEnvironmentOperations>;

export type LoadEnvironmentOperationsAndLogsAction = ReturnType<typeof loadEnvironmentOperationsAndLogs>;
