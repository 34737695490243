import {
    Button,
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    FluentProvider,
    makeStyles,
    OverlayDrawer,
} from '@fluentui/react-components';
import { Dismiss24Regular } from '@fluentui/react-icons';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useCurrentFluent2Theme } from '../../hooks/styling';

export type FormPanelProps = React.PropsWithChildren<{
    isOpen: boolean;
    onDismiss: () => void;
    title: JSX.Element;
    closeButtonAriaLabel?: string;
    isMediumSize?: boolean;
    hasBodyWithFooter?: boolean;
}>;

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: 'Panel_CloseButton_AriaLabel',
        defaultMessage: 'Close',
        description: 'Aria label for the close button in the panel component',
    },
});

/**
 * Styles
 */

const useHeaderStyles = makeStyles({
    root: {
        paddingBottom: '12px',
    },
});

/**
 * END Styles
 */

export const FormPanel: React.FC<FormPanelProps> = (props: FormPanelProps) => {
    const { isOpen, onDismiss, children, title, closeButtonAriaLabel, isMediumSize, hasBodyWithFooter } = props;

    const theme = useCurrentFluent2Theme();

    const { formatMessage } = useIntl();

    const headerStyles = useHeaderStyles();

    return (
        <FluentProvider theme={theme}>
            <OverlayDrawer
                open={isOpen}
                position="end"
                onOpenChange={onDismiss}
                size={isMediumSize ? 'medium' : undefined}
            >
                <DrawerHeader className={headerStyles.root}>
                    <DrawerHeaderTitle
                        action={
                            <Button
                                appearance="subtle"
                                aria-label={closeButtonAriaLabel ?? formatMessage(messages.closeButtonAriaLabel)}
                                icon={<Dismiss24Regular />}
                                onClick={onDismiss}
                            />
                        }
                    >
                        {title}
                    </DrawerHeaderTitle>
                </DrawerHeader>
                {hasBodyWithFooter ? <>{children}</> : <DrawerBody>{children}</DrawerBody>}
            </OverlayDrawer>
        </FluentProvider>
    );
};
